@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('assets/fonts/font.css');

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 999px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@layer base {
  body {
    margin: 0;
    padding: 0;
    font-family: 'beVietNamPro', Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-image: url(./assets/images/b1.png); */
    background-repeat: repeat-y;
  }

  p {
    @apply text-lg;
  }
}

@layer components {
}

@layer utilities {
  .contain {
    @apply sm:mx-auto sm:w-[1531px] -sm:px-2;
  }

  .line {
    @apply h-[0.5px] w-full bg-black/10;
  }

  .level__1:hover .level__1__drop {
    @apply flex;
  }
  .level__2:hover .level__2__drop {
    @apply flex;
  }
  .level__3:hover .level__3__drop {
    @apply flex;
  }
  .level__4:hover .level__4__drop {
    @apply flex;
  }

  .swiper-button-prev,
  .swiper-button-next {
    @apply rounded bg-black/20 p-2 after:text-2xl after:text-white hover:bg-black;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 20px !important;
  }

  .swiper-pagination {
    @apply flex flex-row justify-center;
  }

  .swiper-pagination-bullet {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 20px !important;
    width: 20px !important;
    cursor: pointer !important;
    background-color: black !important;
    opacity: 0.2 !important;
    text-align: center !important;
    color: white !important;
    font-size: 10px !important;
  }

  .swiper-pagination-bullet-active {
    color: #fff !important;
    background: #007aff !important;
  }

  .fz_dtgame {
    font-family: 'fz_dtgame', Arial, Helvetica, sans-serif;
  }

  .svn_shikimaru {
    font-family: 'SVN_Shikimaru', Arial, Helvetica, sans-serif;
  }

  .otf_kaisho {
    font-family: 'OTF_Kaisho', Arial, Helvetica, sans-serif;
  }

  .catpc {
    width: 100px;
    height: 125px;
    margin: 0 auto;
    object-fit: contain;
    background-image: url('assets/images/catpc.png');
    -webkit-animation: moveXpc 1s steps(32) infinite;
    -moz-animation: moveXpc 1s steps(32) infinite;
    -o-animation: moveXpc 1s steps(32) infinite;
    animation: moveXpc 1s steps(32) infinite;
  }

  @-webkit-keyframes moveXpc {
    from {
      background-position-x: 0px;
    }
    to {
      background-position-x: -3200px;
    }
  }
  @-moz-keyframes moveXpc {
    from {
      background-position-x: 0px;
    }
    to {
      background-position-x: -3200px;
    }
  }
  @-o-keyframes moveXpc {
    from {
      background-position-x: 0px;
    }
    to {
      background-position-x: -3200px;
    }
  }
  @keyframes moveXpc {
    from {
      background-position-x: 0px;
    }
    to {
      background-position-x: -3200px;
    }
  }

  .catmobile {
    width: 50px;
    height: 63px;
    margin: 0 auto;
    object-fit: contain;
    background-image: url('assets/images/catmobile.png');
    -webkit-animation: moveXmobile 1s steps(32) infinite;
    -moz-animation: moveXmobile 1s steps(32) infinite;
    -o-animation: moveXmobile 1s steps(32) infinite;
    animation: moveXmobile 1s steps(32) infinite;
  }

  @-webkit-keyframes moveXmobile {
    from {
      background-position-x: 0px;
    }
    to {
      background-position-x: -1600px;
    }
  }
  @-moz-keyframes moveXmobile {
    from {
      background-position-x: 0px;
    }
    to {
      background-position-x: -1600px;
    }
  }
  @-o-keyframes moveXmobile {
    from {
      background-position-x: 0px;
    }
    to {
      background-position-x: -1600px;
    }
  }
  @keyframes moveXmobile {
    from {
      background-position-x: 0px;
    }
    to {
      background-position-x: -1600px;
    }
  }
}
